let cancelSourceToken;

export default function({ $axios, store, app }) {
  cancelSourceToken = $axios.CancelToken.source();

  if (process.server) {
    // eslint-disable-next-line
    $axios.defaults.httpsAgent = new require('https').Agent({ rejectUnauthorized: false });
  }

  const xParams = [];
  if (process.env.IS_BETA) {
    xParams.push('beta');
  }

  if (xParams.length > 0) {
    $axios.setHeader('X-Params', xParams.join(','));
  }

  $axios.onRequest((config) => {
    if (process.env.NODE_ENV === 'production') {
      config.url = config.url.replace(/^\/api\//gi, '');
    }
    if (cancelSourceToken) {
      config.cancelToken = cancelSourceToken.token;
    }

    return config;
  });

  $axios.onResponse((response) => {
    store.commit('maintenance/DISABLE');
    return response;
  });

  $axios.onError((error) => {
    if (error.config.processErrors === false) {
      return Promise.reject(error);
    }

    const code = parseInt(error.response && error.response.status, 10);
    let message = null;

    switch (code) {
      case 306:
        if (process.env.BASE_BETA_URL) {
          const token = error.response.headers['x-token'];
          window.location.href = process.env.BASE_BETA_URL + '/impersonate/?token=' + token;

          return Promise.resolve(false);
        }

        return Promise.resolve(error.response);

      case 401:
        app.$auth.strategy.reset();
        return app.router.push('/login');

      case 422:
        if (error.response.data && error.response.data.data) {
          const data = error.response.data.data;
          if (Object.keys(data).length > 1) {
            message = '';

            if (Object.keys(data).includes('exception')) {
              message = error.response.message;
              break;
            }

            Object.keys(data).forEach(function(value) {
              message += data[value][0] + '\n';
            });
          } else {
            if (Object.keys(data).includes('exception')) {
              message = error.response.message;
              break;
            }

            message = data[Object.keys(data)[0]][0];
          }
        }

        break;

      case 503:
        store.commit('maintenance/ENABLE');
        cancelSourceToken.cancel('Request canceled.');
        window.location = app.router.options.base + 'maintenance';
        return app.router.push('/maintenance');
    }

    if ((code < 200 || code >= 400) && !message) {
      if (!error.response.data.message) {
        message = error.response.statusText;
      } else {
        message = error.response.data.message;
      }
    }

    if (!error.config.hideToastr) {
      const toaster = store.state.layout.isSmallDevice
        ? 'b-toaster-bottom-center'
        : 'b-toaster-top-right';

      // eslint-disable-next-line
      $nuxt.$bvToast.toast(message, {
        toaster,
        solid: true,
        appendToast: false,
        autoHideDelay: 5000,
        variant: 'danger',
      });
    }

    return Promise.reject(error);
  });
}
