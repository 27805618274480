import { ITEMS_PER_PAGE } from '~/constants';

export default () => ({
  state: {
    data: {},
    list: [],
    pagination: {
      last_page: null,
      current_page: 1,
      total: 0,
    },
    isListLoading: false,
    isDataLoading: false,
    queryParams: {},
  },

  actions: {
    // use this action only for the first list page
    async GET_LIST({ commit, state }, payload) {
      const params = payload && payload.params;

      const paginationQuery = {
        page: (params && params.page) || state.pagination.current_page,
        'per-page': (params && params['per-page']) || state.pagination.per_page,
      };

      const queryParams = {
        ...paginationQuery,
        ...params,
      };

      commit('SET_LIST_LOADING', true);

      await this.$axios.get(state.requestUrl, { params: queryParams })
        .then((res) => {
          const { data } = res.data;
          const { meta } = res.data;

          // if data needs additional changes
          if (payload?.dataFormatterFunction) {
            payload.dataFormatterFunction(commit, res.data);
          } else {
            commit('SET_LIST', data);
          }

          commit('UPDATE_PARAMS', queryParams);
          commit('UPDATE_PAGINATION', {
            current_page: meta.current_page,
            per_page: meta.per_page,
            last_page: meta.last_page,
            total: meta.total,
          });
        })
        .catch(err => err)
        .finally(() => {
          commit('SET_LIST_LOADING', false);
        });
    },

    async GET_NEXT_LIST_PAGE({ commit, state }, payload) {
      const params = payload && payload.params;

      const paginationQuery = {
        page: (params && params.page) || (state.pagination.current_page + 1),
        'per-page': (params && params['per-page']) || state.pagination.per_page,
      };

      const queryParams = {
        ...state.queryParams,
        ...paginationQuery,
        ...params,
      };

      commit('SET_LIST_LOADING', true);

      await this.$axios.get(state.requestUrl, { params: queryParams })
        .then((res) => {
          const { data } = res.data;
          const { meta } = res.data;
          commit('PUSH_DATA_TO_LIST', data);
          commit('UPDATE_PARAMS', queryParams);
          commit('UPDATE_PAGINATION', {
            current_page: meta.current_page,
            per_page: meta.per_page,
            last_page: meta.last_page,
            total: meta.total,
          });
        })
        .catch(err => err)
        .finally(() => {
          commit('SET_LIST_LOADING', false);
        });
    },

    async GET_ITEM({ commit, state }, { id, params, endpoint = null }) {
      commit('SET_DATA_LOADING', true);

      const { data } = await this.$axios.get(
        `${endpoint || state.requestUrl}/${id}`,
        {
          params,
        },
      );
      commit('SET_ITEM', data.data);

      commit('SET_LIST_LOADING', false);
    },

    async UPDATE_ITEM({ commit, state }, { data, id }) {
      commit('SET_DATA_LOADING', true);

      await this.$axios.$put(`/api/${state.endpoint}/${id}`, data)
        .then((res) => {
          commit('SET_ITEM', res.data);
        })
        .catch(err => err)
        .finally(() => {
          commit('SET_DATA_LOADING', false);
        });
    },

    async SAVE_ITEM({ commit, state }, { data }) {
      commit('SET_DATA_LOADING', true);

      await this.$axios.$post(`/api/${state.endpoint}`, data)
        .then((res) => {
          commit('SET_ITEM', res.data);
        })
        .catch(err => err)
        .finally(() => {
          commit('SET_DATA_LOADING', false);
        });
    },

    async CLEAR_PREVIOUS_DATA({ commit }) {
      await commit('CLEAR_PAGINATION');
      await commit('CLEAR_LIST');
      await commit('CLEAR_PARAMS');
    },
  },

  mutations: {
    SET_LIST(state, data) {
      state.list = data;
    },
    PUSH_DATA_TO_LIST(state, data) {
      state.list = state.list.concat(data);
    },
    SET_ITEM(state, data) {
      state.data = data;
    },
    SET_LIST_LOADING(state, status) {
      state.isListLoading = status;
    },
    SET_DATA_LOADING(state, status) {
      state.isDataLoading = status;
    },
    UPDATE_PAGINATION(state, data) {
      state.pagination = {
        ...state.pagination,
        ...data,
      };
    },
    UPDATE_PARAMS(state, data) {
      state.queryParams = data;
    },
    CLEAR_PAGINATION(state) {
      state.pagination = Object.assign({}, {
        per_page: ITEMS_PER_PAGE,
        last_page: 0,
        current_page: 1,
        total: 0,
      });
    },
    CLEAR_LIST(state) {
      state.list = [];
    },
    CLEAR_ITEM(state) {
      state.data = {};
    },
    CLEAR_PARAMS(state) {
      state.queryParams = {};
    },
    CLEAR_ITEM_DATA(state) {
      state.data = {};
    },
  },

  getters: {},
});
