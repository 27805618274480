import { TrainingPlanItem } from './training';
import { UserFollowee } from './users';

export enum NotificationType {
  Comment = 'new_comment_on_post',
  Fiver = 'new_fiver',
  Follower = 'new_follower',
  IncompleteWorkout = 'uncompleted_workout',
  Reply = 'new_reply_to_comment',
};

export enum ReactionType {
  Comment = 3,
  Leaderboard = 4,
  Post = 2,
};

type NotificationCommentParams = {
  comment_id: number,
  post_id: number,
};

type NotificationFiverParams = {
  post_id: number | undefined,
  user_reaction_id: number,
  user_reaction_type: ReactionType,
};

type NotificationFollowerParams = {
  follower_id: number,
  user_follow_id: number,
};

type NotificationIncompleteWorkoutParams = {
  training_plan_id: number,
};

export type NotificationItem = {
  created_at: string,
  id: string,
  is_read: boolean,
  message: string,
  params: NotificationCommentParams | NotificationFiverParams | NotificationFollowerParams | NotificationIncompleteWorkoutParams,
  training_plan: TrainingPlanItem | null,
  type: NotificationType,
  user: {
    first_name: string,
    id: number,
    last_name: string,
    own_followee: UserFollowee,
  },
};
