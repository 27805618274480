import Vue from 'vue';
import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/reactions',
  postReactions: {},
  commentReactions: {},
});

export const actions = {
  ...commonStore.actions,
  async GET_REACTIONS({ commit }, { postId, commentId, type }) {
    const modelId = commentId || postId;
    const params = {
      'filter[model_id]': modelId,
      'filter[type]': type,
      sort: '-created_at',
      page: 1,
      'per-page': 11,
    };

    const { data, meta } = await this.$axios.$get('/api/reactions', {
      params,
    });

    commit('SET_REACTIONS', {
      data, meta, postId, commentId,
    });
  },

  async POST_REACTION({ commit, state }, { modelId, postId, commentId, reactionId, add, type, moduleName, parentCommentId, isSinglePostPage }) {
    try {
      if (add) {
        const { data } = await this.$axios.$post('/api/reactions', {
          type,
          model_id: modelId,
        });

        if (moduleName) {
          await commit(`${moduleName}/ADD_REACTION`, { data, postId, commentId, parentCommentId, modelId, isSinglePostPage }, { root: true });
        }
      } else {
        await this.$axios.$delete(`/api/reactions/${reactionId}`);

        if (moduleName) {
          await commit(`${moduleName}/REMOVE_REACTION`, { postId, commentId, parentCommentId, modelId, isSinglePostPage }, { root: true });
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },

};

export const mutations = {
  ...commonStore.mutations,
  SET_REACTIONS(state, { data, meta, postId, commentId }) {
    const isComment = commentId;

    if (isComment) {
      Vue.set(state.commentReactions, commentId, {
        items: data,
        total: meta.total,
      });
    } else {
      Vue.set(state.postReactions, postId, {
        items: data,
        total: meta.total,
      });
    }
  },
};
