import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { State } from '~/data/types/store';

export const useImportedActivity = () => {
  const store = useStore<State>();
  const isLoading = computed(() => store.state.importedActivity.isLoading);
  const importedActivity = computed(() => store.state.importedActivity.data);

  const clearData = () => {
    store.dispatch('trainingPlanItem/CLEAR_DATA');
  };

  const getImportedActivity = async(id: number) => {
    await store.dispatch('importedActivity/GET_IMPORTED_ACTIVITY', id);
  };

  return {
    clearData,
    getImportedActivity,
    importedActivity,
    isLoading,
  };
};
