/* global window */
import Vue from 'vue';
import findIndex from 'lodash/findIndex';
import { addHours } from 'date-fns';

import storeInit from '~/helpers/store';
import { POST_FILTER } from '~/constants';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/posts',
  data: {},
  draft: {},
  sliderConstants: null,
});

const normalizePostData = (data = [], commit) => {
  data.forEach((post) => {
    if (post.latestComments.length) {
      let reversedList = post.latestComments.slice().reverse();
      let showLoadMoreButton = false;

      if (reversedList.length >= 3) {
        reversedList = reversedList.slice(1);
        showLoadMoreButton = true;
      }

      commit('comments/SET_COMMENTS', {
        items: reversedList,
        showLoadMoreButton,
      }, { root: true });

      post.latestComments.forEach((comment) => {
        if (comment.latestReplies.length) {
          let reversedRepliesList = comment.latestReplies.slice().reverse();
          let showLoadMoreButton = false;

          if (reversedRepliesList.length >= 3) {
            reversedRepliesList = reversedRepliesList.slice(1);
            showLoadMoreButton = true;
          }

          commit('comments/SET_REPLIES', {
            items: reversedRepliesList,
            showLoadMoreButton,
          }, { root: true });
        }
      });
    }
  });
};

export const actions = {
  ...commonStore.actions,
  async GET_LIST({ commit, state, dispatch }, payload) {
    const params = payload && payload.params;

    const paginationQuery = {
      page: (params && params.page) || state.pagination.current_page,
      'per-page': (params && params['per-page']) || state.pagination.per_page,
    };

    const queryParams = {
      ...state.queryParams,
      ...paginationQuery,
      ...params,
    };

    commit('SET_LIST_LOADING', true);

    await this.$axios.get(state.requestUrl, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;

        // Only cache if we are querying the first page of all posts
        if (queryParams[`filter[${POST_FILTER.ALL_POSTS}]`] && queryParams.page === 1) {
          dispatch('CACHE_POSTS_FIRST_PAGE_DATA', data);
        }

        // normalize post comments data
        normalizePostData(data, commit);
        commit('SET_LIST', data);
        commit('UPDATE_PAGINATION', {
          current_page: meta.current_page,
          per_page: meta.per_page,
          last_page: meta.last_page,
          total: meta.total,
        });
      })
      .catch((err) => { throw new Error(err); })
      .finally(() => {
        commit('SET_LIST_LOADING', false);
      });
  },

  async GET_ITEM({ commit, state }, { id, params }) {
    commit('SET_DATA_LOADING', true);
    const { data } = await this.$axios.get(
        `${state.requestUrl}/${id}`,
        {
          params,
        },
    );
    normalizePostData([data.data], commit);

    commit('SET_ITEM', data.data);
    commit('SET_DATA_LOADING', false);
  },

  async GET_NEXT_LIST_PAGE({ commit, state }, payload) {
    const params = payload && payload.params;

    const paginationQuery = {
      page: (params && params.page) || (state.pagination.current_page + 1),
      'per-page': (params && params['per-page']) || state.pagination.per_page,
    };

    const queryParams = {
      ...state.queryParams,
      ...paginationQuery,
      ...params,
    };

    commit('SET_LIST_LOADING', true);

    await this.$axios.get(state.requestUrl, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;

        // normalize post comments data
        normalizePostData(data, commit);
        commit('PUSH_DATA_TO_LIST', data);
        commit('UPDATE_PAGINATION', {
          current_page: meta.current_page,
          per_page: meta.per_page,
          last_page: meta.last_page,
          total: meta.total,
        });
      })
      .catch(err => err)
      .finally(() => {
        commit('SET_LIST_LOADING', false);
      });
  },

  async CREATE_POST({ commit, dispatch }, action) {
    try {
      commit('SET_LIST_LOADING', true);
      const postData = {
        text: action.text,
      };

      if (action.gif_url) {
        postData.gif_url = action.gif_url;
      }
      const { data } = await this.$axios.$post('/api/posts/', postData);

      if (action.images) {
        for (let i = 0; i < action.images.length; i++) {
          await dispatch('posts/ATTACH_IMAGE', { postId: data.id, file: action.images[i].file }, { root: true });
        }
      }

      await dispatch('posts/POST_CREATED_POST', { postId: data.id }, { root: true });
    } catch (error) {
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },
  async POST_CREATED_POST({ commit }, { postId }) {
    try {
      commit('SET_LIST_LOADING', true);
      await this.$axios.$put(`/api/posts/publish/${postId}`);
    } catch (error) {
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  async ATTACH_IMAGE(_, action) {
    const formData = new FormData();
    formData.append('image', action.file);
    const url = action.commentId ? `/api/comments/${action.commentId}/attach-image` : `/api/posts/${action.postId}/attach-image`;

    try {
      return await this.$axios.$post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {}
  },
  async DELETE_POST({ commit }, { postId }) {
    try {
      commit('SET_LIST_LOADING', true);
      await this.$axios.$delete(`/api/posts/${postId}`);
      await commit('DELETE_POST_FROM_LIST', { postId });
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  async GET_POST_DRAFT_DATA({ commit }, action) {
    try {
      const { data } = await this.$axios.$put(`/api/posts/${action.postId}`);
      await commit('SET_DRAFT_DATA', data);
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  async DELETE_DRAFT_DATA({ commit }, action) {
    try {
      commit('SET_LIST_LOADING', true);
      await this.$axios.$delete(`/api/posts/${action.postId}`);
      await commit('REMOVE_DRAFT_DATA');
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  async EDIT_POST({ commit, dispatch }, action) {
    const postData = {
      text: action.text,
      gif_url: action.gif_url,
    };

    try {
      // update draft data
      commit('SET_LIST_LOADING', true);
      await this.$axios.$put(`/api/posts/${action.draftId}`, postData);

      if (action.deletedImages.length) {
        for (let i = 0; i < action.deletedImages.length; i++) {
          await this.$axios.$delete(`/api/posts/${action.draftId}/delete-image/${action.deletedImages[i].id}`);
        }
      }

      if (action.newImages.length) {
        for (let i = 0; i < action.newImages.length; i++) {
          await dispatch('posts/ATTACH_IMAGE', { postId: action.draftId, file: action.newImages[i].file }, { root: true });
        }
      }

      const { data } = await this.$axios.$put(`/api/posts/publish/${action.draftId}`);
      await commit('UPDATE_POST_DATA', data);
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  async GET_POST_DATA({ commit }, action) {
    try {
      commit('SET_LIST_LOADING', true);
      const { data } = await this.$axios.$get(`/api/posts/${action.postId}`);
      await commit('UPDATE_POST_DATA', data);
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  // COMMENTS ACTIONS
  async GET_COMMENTS({ commit }, action) {
    try {
      commit('SET_LIST_LOADING', true);
      const { data } = await this.$axios.$get('/api/comments/', {
        params: action.params,
      });

      await commit('PUSH_COMMENTS_TO_LIST', data);
    } catch (e) {} finally {
      commit('SET_LIST_LOADING', false);
    }
  },

  async GET_SLIDER_CONSTANTS({ commit }) {
    try {
      commit('SET_LIST_LOADING', true);
      const { data } = await this.$axios.$get('/api/posts/constants');
      await commit('SET_SLIDER_CONSTANTS', data.slidersSettings);
    } catch (e) {} finally {
      commit('SET_LIST_LOADING', false);
    }
  },
  CACHE_POSTS_FIRST_PAGE_DATA(_, data) {
    if (!window || !window.localStorage) {
      return null;
    }

    window.localStorage.setItem('cache.postsFirstPage', JSON.stringify({
      data,
      createdAt: (new Date()).toISOString(),
    }));
  },
  RESTORE_CACHED_FIST_PAGE_DATA({ commit }) {
    if (!window || !window.localStorage) {
      return false;
    }

    const data = JSON.parse(window?.localStorage?.getItem('cache.postsFirstPage') || '{}');
    if (!data) {
      return false;
    }

    if (!Object.keys(data).length) {
      return false;
    }

    const expiryDate = addHours(new Date(data.createdAt), 6);
    const expiryTime = expiryDate.getTime();
    const currentTime = new Date().getTime();
    if (currentTime > expiryTime) {
      window.localStorage?.removeItem('cache.postsFirstPage');
      return false;
    }

    // normalize post comments data
    normalizePostData(data.data, commit);
    commit('SET_LIST', data.data);

    return true;
  },
};

export const mutations = {
  ...commonStore.mutations,
  ADD_REACTION(state, { postId, data, isSinglePostPage }) {
    if (isSinglePostPage) {
      state.data.ownReaction = data;
    } else {
      const post = state.list.find(x => x.id === postId);

      if (post) {
        post.ownReaction = data;
      }
    }
  },
  REMOVE_REACTION(state, { postId }) {
    if (state.data?.id === postId) {
      state.data.ownReaction = null;
    } else {
      state.list.find(x => x.id === postId).ownReaction = null;
    }
  },
  DELETE_POST_FROM_LIST(state, { postId }) {
    const postIndex = findIndex(state.list, { id: postId });
    state.list.splice(postIndex, 1);
  },
  SET_DRAFT_DATA(state, data) {
    state.draft = {
      ...data,
    };
  },
  REMOVE_DRAFT_DATA(state) {
    state.draft = {};
  },
  UPDATE_POST_DATA(state, data) {
    const postIndex = findIndex(state.list, { id: data.id });
    Vue.set(state.list, postIndex, data);
  },
  DECREASE_COMMENT_COUNTER(state, { postId }) {
    const postIndex = findIndex(state.list, { id: postId });

    Vue.set(state.list, postIndex, {
      ...state.list[postIndex],
      commentsCount: state.list[postIndex].commentsCount > 0 ? state.list[postIndex].commentsCount -= 1 : 0,
    });
  },
  SET_SLIDER_CONSTANTS(state, data) {
    Vue.set(state, 'sliderConstants', data);
  },
};
