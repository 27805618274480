import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/races',
  upcomingRacesList: null,
  completedRacesList: null,
});

const splitDataForUpcomingRacesList = (commit, data) => {
  commit('SET_UPCOMING_LIST', { data: data.data, isLoadMore: false });
};

const splitDataForCompletedRacesList = (commit, data) => {
  commit('SET_COMPLETED_LIST', { data: data.data, isLoadMore: false });
};

export const actions = {
  ...commonStore.actions,
  async CREATE_NEW_RACE({ commit }, data) {
    commit('SET_LIST_LOADING', true);
    try {
      return await this.$axios.$post('/api/races', data);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },
  async UPDATE_RACE({ commit }, { data, id }) {
    try {
      commit('SET_LIST_LOADING', true);
      return await this.$axios.$put(`/api/races/${id}`, data);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },
  GET_UPCOMING_LIST: commonStore.actions.GET_LIST,
  async GET_UPCOMING_RACES_LIST({ dispatch }, action) {
    await dispatch('GET_UPCOMING_LIST', {
      ...action,
      params: {
        'filter[upcoming]': true,
        'per-page': 100,
        sort: 'event_date',
      },
      dataFormatterFunction: splitDataForUpcomingRacesList,
    });
  },
  GET_COMPLETED_LIST: commonStore.actions.GET_LIST,
  async GET_COMPLETED_RACES_LIST({ dispatch }, action) {
    await dispatch('GET_COMPLETED_LIST', {
      ...action,
      params: {
        'filter[completed]': true,
        'per-page': 100,
        sort: '-event_date',
      },
      dataFormatterFunction: splitDataForCompletedRacesList,
    });
  },
  DELETE_RACE({ commit }, { id }) {
    commit('SET_LIST_LOADING', true);

    return this.$axios.$delete(`/api/races/${id}`).then(() => {
      return Promise.resolve();
    }).catch(error => Promise.reject(error)).finally(() => {
      commit('SET_LIST_LOADING', false);
    });
  },
};

export const mutations = {
  ...commonStore.mutations,
  SET_UPCOMING_LIST(state, { data, isLoadMore }) {
    if (isLoadMore) {
      state.upcomingRacesList = [...state.upcomingRacesList, ...data];
    } else {
      state.upcomingRacesList = data;
    }
  },
  SET_COMPLETED_LIST(state, { data, isLoadMore }) {
    if (isLoadMore) {
      state.completedRacesList = state.completedRacesList.concat(data);
    } else {
      state.completedRacesList = data;
    }
  },
};

export const getters = {
  ...commonStore.getters,
  getRaceById: state => (raceId) => {
    return state.upcomingRacesList.find(item => item.id === +raceId)
      || state.completedRacesList.find(item => item.id === +raceId);
  },
};
