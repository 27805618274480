import { addDays } from 'date-fns';
import { REMEMBER_ME_DAYS } from '~/constants';

// https://github.com/nuxt-community/auth-module/issues/283#issuecomment-932940415
export default function({ $auth }) {
  const rememberExpiry = $auth.$storage.getCookie('_token_expiration.local');
  const rememberMe = $auth.$storage.getCookie('_token_remember.local');

  // If we have an expiry set, update the token expiration to x days from now
  if (rememberMe && rememberExpiry) {
    const expiryDate = addDays(new Date(), REMEMBER_ME_DAYS);
    $auth.$storage.setCookie('_token_expiration.local', expiryDate.getTime());
    $auth.$storage.setCookie('_token_expiration.local', expiryDate.getTime(), { expires: REMEMBER_ME_DAYS });
    $auth.options.cookie.options.expires = rememberExpiry;
  } else {
    $auth.options.cookie.options.expires = null; // reset to 'session'
    $auth.$storage.removeCookie('_token_expiration.local');
    $auth.$storage.removeCookie('_token_remember.local');
  }
};
