<template>
  <ul
    class="imported-activity-stats-section imported-activity-stats-section--placeholder text-center m-0 py-2 px-0 d-grid column-50-50 border-bottom border-light"
  >
    <li class="py-0 px-2 border-right border-light">
      <span class="d-block label font-xs">
        <span class="bg-light text-light">
          Avg Speed
        </span>
      </span>
      <span class="d-block value font-weight-bold">
        <span class="bg-light text-light">
          30kph
        </span>
      </span>
    </li>
    <li class="py-0 px-2">
      <span class="d-block label font-xs">
        <span class="bg-light text-light">
          Max Speed
        </span>
      </span>
      <span class="d-block value font-weight-bold">
        <span class="bg-light text-light">
          55kph
        </span>
      </span>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ImportedActivityStatsSectionPlaceholder',
});
</script>

<style lang="scss" scoped>
.imported-activity-stats-section {
  list-style: none;
}
</style>
