import Vue from 'vue';
import findIndex from 'lodash/findIndex';

export const state = () => ({
  commentDraft: {},
  activeReplyCommentIndex: null,
  comments: {},
  replies: {},
});

const normalizeNextCommentsData = (comments, commit) => {
  comments.forEach((comment) => {
    if (comment.latestReplies.length > 0) {
      let reversedRepliesList = comment.latestReplies.slice().reverse();
      let showLoadMoreButton = false;

      if (reversedRepliesList.length >= 3) {
        reversedRepliesList = reversedRepliesList.slice(1);
        showLoadMoreButton = true;
      }

      commit('SET_REPLIES', {
        items: reversedRepliesList,
        showLoadMoreButton,
      });
    }
  });
};

export const actions = {
  async GET_COMMENT_DRAFT_DATA({ commit, dispatch }, action) {
    const { data } = await this.$axios.$put(`/api/comments/${action.commentId}`);
    try { await commit('SET_COMMENT_DRAFT_DATA', data); } catch (e) {}
  },

  async CREATE_NEW_COMMENT({ commit, dispatch }, action) {
    const commentData = {
      text: action.text,
      post_id: action.post_id,
    };

    if (action.commentParentId) {
      commentData.parent_id = action.commentParentId;
    }

    if (action.gif_url) {
      commentData.gif_url = action.gif_url;
    }
    try {
      const { data: draftData } = await this.$axios.$post('/api/comments', commentData);

      if (action.images) {
        for (let i = 0; i < action.images.length; i++) {
          await dispatch('comments/ATTACH_IMAGE', { commentId: draftData.id, file: action.images[i].file }, { root: true });
        }
      }

      const { data } = await this.$axios.$put(`/api/comments/publish/${draftData.id}`);
      await dispatch('posts/GET_POST_DATA', { postId: action.post_id }, { root: true });
      await commit('PUSH_COMMENT', data);
    } catch (e) {}
  },

  async DELETE_COMMENT_DRAFT_DATA({ commit, dispatch }, action) {
    await this.$axios.$delete(`/api/comments/${action.draftId}`);
    await commit('REMOVE_COMMENT_DRAFT_DATA');
  },

  async DELETE_COMMENT({ commit, dispatch }, { commentId, postId, parentId }) {
    try {
      await this.$axios.$delete(`/api/comments/${commentId}`);
      await commit('DELETE_COMMENT_FROM_LIST', { commentId, postId, parentId });
      await dispatch('posts/GET_POST_DATA', { postId }, { root: true });
      await commit('SET_STATUS_READY');
    } catch (e) {}
  },

  async EDIT_COMMENT({ commit, dispatch }, action) {
    const commentData = {
      text: action.text,
      gif_url: action.gif_url,
    };

    try {
      // update draft data
      await this.$axios.$put(`/api/comments/${action.draftId}`, commentData);

      if (action.deletedImages.length) {
        for (let i = 0; i < action.deletedImages.length; i++) {
          await this.$axios.$delete(`/api/comments/${action.draftId}/delete-image/${action.deletedImages[i].id}`);
        }
      }

      if (action.newImages.length) {
        for (let i = 0; i < action.newImages.length; i++) {
          await dispatch('comments/ATTACH_IMAGE', { commentId: action.draftId, file: action.newImages[i].file }, { root: true });
        }
      }

      // comment draft
      const { data } = await this.$axios.$put(`/api/comments/publish/${action.draftId}`);

      await commit('UPDATE_COMMENT_DATA', data);
    } catch (e) {}
  },

  async ATTACH_IMAGE({ commit, state }, action) {
    const formData = new FormData();
    formData.append('image', action.file);

    try {
      return await this.$axios.$post(`/api/comments/${action.commentId}/attach-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {}
  },

  async GET_NEXT_LIST_PAGE({ commit, state }, payload) {
    const params = payload && payload.params;
    const isReply = payload.parentId;
    let prevPagination = null;

    if (isReply) {
      prevPagination = state.replies[payload.parentId].pagination;
    } else {
      prevPagination = state.comments[payload.postId].pagination;
    }

    const paginationQuery = {
      page: (params && params.page) || (prevPagination.current_page + 1),
      'per-page': (params && params['per-page']) || prevPagination.per_page,
    };

    const queryParams = {
      // ...state.queryParams,
      ...paginationQuery,
      ...params,
    };

    const requestUrl = '/api/comments';
    await this.$axios.get(requestUrl, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;

        commit('PUSH_DATA_TO_LIST', {
          items: data,
          pagination: {
            current_page: meta.current_page,
            per_page: meta.per_page,
            last_page: meta.last_page,
            total: meta.total,
          },
          showLoadMoreButton: meta.last_page !== meta.current_page,
          postId: payload.postId,
          parentId: payload.parentId,
        });

        normalizeNextCommentsData(data, commit);
      })
      .catch(err => err);
  },
};

export const mutations = {
  SET_COMMENTS(state, action) {
    Vue.set(state.comments, action.items[0].post_id, {
      items: action.items,
      showLoadMoreButton: action.showLoadMoreButton,
      pagination: {
        current_page: 0,
      },
    });
  },
  SET_COMMENT_DRAFT_DATA(state, data) {
    state.commentDraft = {
      ...data,
    };
  },
  REMOVE_COMMENT_DRAFT_DATA(state) {
    state.commentDraft = {};
  },
  UPDATE_COMMENT_DATA(state, data) {
    const isReply = data.parent_id;

    if (!isReply) {
      const commentsList = state.comments[data.post_id].items;
      const commentIndex = findIndex(commentsList, { id: data.id });
      Vue.set(commentsList, commentIndex, { ...commentsList[commentIndex], ...data });
    } else {
      const repliesList = state.replies[data.parent_id].items;
      const replyIndex = findIndex(repliesList, { id: data.id });
      Vue.set(repliesList, replyIndex, { ...repliesList[replyIndex], ...data });
    }
  },
  PUSH_COMMENT(state, data) {
    const isReply = data.parent_id;

    if (!isReply) {
      const oldArray
          = state.comments[data.post_id] ? state.comments[data.post_id].items : null;

      if (oldArray) {
        oldArray.push(data);
        Vue.set(state.comments[data.post_id], 'items', oldArray);
      } else {
        Vue.set(state.comments, [data.post_id], {
          items: [data],
          pagination: {
            page: 0,
          },
        });
      }
    } else {
      const oldArray = state.replies[data.parent_id] ? state.replies[data.parent_id].items : null;

      if (oldArray) {
        oldArray.push(data);
        Vue.set(state.replies[data.parent_id], 'items', oldArray);
      } else {
        Vue.set(state.replies, [data.parent_id], {
          items: [data],
          pagination: {
            page: 0,
          },
        });
      }
    }

    const postIndex = findIndex(state.list, { id: data.post_id });
    Vue.set(state.list, postIndex, {
      ...state.list[postIndex],
      commentsCount: state.list[postIndex].commentsCount += 1,
    });
  },
  DELETE_COMMENT_FROM_LIST(state, { commentId, postId, parentId }) {
    const isReply = parentId;

    if (!isReply) {
      const commentsList = state.comments[postId].items;
      const commentIndex = findIndex(commentsList, { id: commentId });
      commentsList.splice(commentIndex, 1);
    } else {
      const repliesList = state.replies[parentId].items;
      const replyIndex = findIndex(repliesList, { id: commentId });
      repliesList.splice(replyIndex, 1);
    }
  },
  ACTIVE_REPLY_COMMENT(state, data) {
    Vue.set(state, 'activeReplyCommentIndex', data);
  },
  SET_REPLIES(state, data) {
    Vue.set(state.replies, data.items[0].parent_id, {
      items: data.items,
      showLoadMoreButton: data.showLoadMoreButton,
      pagination: {
        current_page: 0,
      },
    });
  },
  PUSH_DATA_TO_LIST(state, action) {
    const isReply = action.parentId;
    const commentsObject = isReply ? state.replies[action.parentId] : state.comments[action.postId];
    let updatedItems = [];

    // spread first page data with preview items
    // to prevent duplicated items
    if (commentsObject.pagination.current_page === 0) {
      const presentIds = commentsObject.items.map(item => item.id);
      const newItems = action.items.filter(item => !presentIds.includes(item.id)).reverse();
      updatedItems = [
        ...newItems,
        ...commentsObject.items,
      ];
    } else {
      const newItems = action.items.reverse();
      updatedItems = [
        ...newItems,
        ...commentsObject.items,
      ];
    }

    Vue.set(commentsObject, 'items', updatedItems);
    Vue.set(commentsObject, 'pagination', {
      ...commentsObject.pagination,
      ...action.pagination,
    });
    Vue.set(commentsObject, 'showLoadMoreButton', action.showLoadMoreButton);
  },
  ADD_REACTION(state, { commentId, postId, data, parentCommentId }) {
    if (parentCommentId) {
      const comment = state.replies[parentCommentId].items.find(x => x.id === commentId);
      comment.ownReaction = data;
    } else {
      const comment = state.comments[postId].items.find(x => x.id === commentId);
      comment.ownReaction = data;
    }
  },
  REMOVE_REACTION(state, { commentId, postId, parentCommentId }) {
    if (parentCommentId) {
      state.replies[parentCommentId].items.find(x => x.id === commentId).ownReaction = null;
    } else {
      state.comments[postId].items.find(x => x.id === commentId).ownReaction = null;
    }
  },
  CLEAR_COMMENTS_DATA(state) {
    Vue.set(state, 'comments', {});
    Vue.set(state, 'replies', {});
  },
};
