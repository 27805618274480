import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import stonlyInternal from '~/helpers/stonly';

export default defineNuxtPlugin(({ store }, inject) => {
  const key = process.env.STONLY_ID;

  if (!key) {
    return;
  }

  /* eslint-disable */
  !function(s,t,o,n,l,y,_){s.stonlyTrack||((_=s.stonlyTrack=function(){
    _._api?_._api.apply(_,arguments):_.queue.push(arguments)
    }).queue=[],(y=t.createElement(o)).async=!0,
    y.src=n,(l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))
    }(window,document,'script','https://stonly.com/js/tracker/stn.js');
  /* eslint-enable */

  // eslint-disable-next-line no-undef
  inject('stonly', stonlyInternal(stonlyTrack, key, store));
});
