import { format } from 'date-fns';
import { uniqID } from '@/utils/hash';
import { getCookie } from '@/utils/cookieStorage';
import { UserSubscriptionStatus, UserSubscriptionType } from '~/data/types';
import { DateFormat } from '~/helpers/date';

export const state = () => ({
  isLoading: false,
  settings: null, // UserSettings type
});

const MUTATION_TYPES = {
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  SET_HAS_CONFIRMED_EMAIL: 'SET_HAS_CONFIRMED_EMAIL',
};

export const mutations = {
  [MUTATION_TYPES.SET_LOADING_STATE](state, payload) {
    state.isLoading = payload.isLoading;
  },
  [MUTATION_TYPES.SET_USER_SETTINGS](state, payload) {
    // Transform to expected UserSettings type
    state.settings = {
      isScheduledWorkoutNotificationOn: payload?.data?.is_scheduled_reminder_on || false,
      isIncompleteWorkoutNotificationOn: payload?.data?.is_uncompleted_reminder_on || false,
      isFiverNotificationOn: payload?.data?.is_fiver_notification_on || false,
      isCommentNotificationOn: payload?.data?.is_comment_notification_on || false,
      isHideLocationOn: payload?.data?.hide_location || false,
      isAutoShareWorkoutsOn: payload?.data?.allow_auto_share_workouts || false,
    };
  },
};

export const actions = {
  async login({ commit }, payload) {
    let deviceToken = getCookie('device_token');

    if (!deviceToken) {
      deviceToken = uniqID(navigator.userAgent);
      document.cookie = `device_token=${deviceToken}`;
    }

    const defaultPayload = {
      device_token: deviceToken,
      platform: 'web',
    };

    try {
      const response = await this.$axios.post('/api/users/login', { ...payload, ...defaultPayload });
      return response;
    } catch (e) {
      this.$logger.error('user/login', e.response.data.message);
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async resetPasswordRequest(_, payload) {
    try {
      const response = await this.$axios.$post('/api/users/reset-password', payload);
      return response;
    } catch (e) {
      this.$logger.error('user/resetPasswordRequest', e.response.data.message);
    }
  },
  async setNewPasswordRequest({ commit }, payload) {
    try {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: true });
      const response = await this.$axios.$post('/api/users/set-password', payload, { processErrors: false });
      return response;
    } catch (e) {
      this.$logger.error('user/setNewPasswordRequest', e.response.data.message);
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async register({ commit }, payload) {
    const apiURL = '/api/users/register';
    try {
      const deviceToken = uniqID(navigator.userAgent);
      document.cookie = `device_token=${deviceToken}`;

      const defaultPayload = {
        device_token: deviceToken,
        platform: 'web',
      };
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: true });
      return await this.$axios.$post(apiURL, { ...payload, ...defaultPayload });
    } catch (e) {
      this.$logger.error('user/register', e.response.data.message);
      throw new Error(e);
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async subscribe({ commit }, payload) {
    const apiURL = '/api/users/subscribe';
    try {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: true });
      const { token, ...rest } = payload;
      return await this.$axios.$post(apiURL, rest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      this.$logger?.error('user/subscribe', e?.response?.data?.message);
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async updateUser({ commit }, data) {
    try {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: true });
      const response = await this.$axios.put('/api/users/me', data);
      // commit(MUTATION_TYPES.SET_DATA, response);
      return response.data;
    } catch (e) {
      return e.response;
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async changeSettings({ commit }, { data, endpoint }) {
    try {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: true });
      const response = await this.$axios.put(`/api/${endpoint}`, data);
      commit(MUTATION_TYPES.SET_USER_SETTINGS, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async getUserSettings({ commit }) {
    try {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: true });
      const response = await this.$axios.$get('/api/users/my-settings');
      commit(MUTATION_TYPES.SET_USER_SETTINGS, response);
      return response.data;
    } catch (e) {
      return e.response;
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_STATE, { isLoading: false });
    }
  },
  async confirmEmail(_, token) {
    return await this.$axios.post(`/api/users/confirm-email/${token}`);
  },
  async resendConfirmationEmail() {
    try {
      const response = await this.$axios.$post('/api/users/confirm-email-resend', {
        hideToastr: true,
      });
      return response.data;
    } catch (e) {
      return e.response;
    }
  },
};

// const checkFeature = (rootState, featureName) => {
//   const features = rootState.auth.user?.data?.enabled_features || [];
//   return features.includes(featureName);
// };

export const getters = {
  // isTrainingStatsEnabled(_, __, rootState) {
  //   return checkFeature(rootState, 'release-training-stats');
  // },
  hasConfirmedEmail: (_, __, rootState) => {
    return rootState?.auth?.user?.data?.is_email_confirmed;
  },
  isUserUnsubscribed: (_, __, rootState) => {
    const user = rootState.auth.user?.data;

    if (user) {
      return (user.subscription?.status !== 'trialing' && user.subscription?.status !== 'active' && user.subscription?.status !== 'past_due');
    }

    return false;
  },
  userTimeZone(_, __, rootState) {
    return rootState.auth.user?.data?.timezone ?? 'America/New_York';
  },
  userCurrentDate() {
    // TODO: Check bug now replaced moment with date-fns...
    // to prevent safari bugs with dates YYYY-MM-DD[T]HH:mm:ss format is needed to parse new date
    // const dateString = formatInTimeZone(new Date(), getters.userTimeZone, "yyyy-MM-dd'T'HH:mm:ss");
    const dateString = format(new Date(), DateFormat.DateTimeLocal);
    return new Date(dateString);
  },
  isStripeSubscription: (_, __, rootState) => {
    const user = rootState.auth.user?.data;

    if (user) {
      return user.subscription?.type === UserSubscriptionType.Stripe;
    }

    return false;
  },
  isActiveUser: (_, __, rootState) => {
    const user = rootState.auth.user?.data;
    return user?.subscription?.status === UserSubscriptionStatus.Active || user?.subscription?.status === UserSubscriptionStatus.PastDue;
  },
  isTrialUser: (_, __, rootState) => {
    const user = rootState.auth.user?.data;
    return user?.subscription?.status === UserSubscriptionStatus.Trialing;
  },
};
