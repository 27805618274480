import { installPixel } from '~/helpers/facebook';

export default function() {
  const key = process.env.FACEBOOK_ID;

  if (!key) {
    return;
  }

  installPixel(key);
};
