import { useStore } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import { parse } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

import { State } from '~/data/types/store';
import { MeasurementSystem } from '~/data/types';
import { DateFormat } from '~/helpers/date';

export const useUser = () => {
  const store = useStore<State>();

  const user = computed(() => store.state.auth.user.data);
  const timeZone = computed<string>(() => store.getters['user/userTimeZone']);
  const currentDate = computed<Date>(() => store.getters['user/userCurrentDate']);
  const currentDateUTC = computed(() => {
    const dateString = formatInTimeZone(currentDate.value, timeZone.value, DateFormat.Date);
    return zonedTimeToUtc(dateString, timeZone.value);
  });
  const createdDateUTC = computed(() => {
    const date = parse(store.state.auth.user.data.created_at, DateFormat.ISO, new Date());
    const dateString = formatInTimeZone(date, timeZone.value, DateFormat.Date);
    return zonedTimeToUtc(dateString, timeZone.value);
  });
  const isMetricSystem = computed(() => store.state.auth.user.data.measurement_system === MeasurementSystem.Metric);
  const measurementSystem = computed(() => store.state.auth.user.data.measurement_system || MeasurementSystem.Metric);
  const isActiveUser = computed(() => store.getters['user/isActiveUser']);
  const isTrialUser = computed(() => store.getters['user/isTrialUser']);
  const isUnsubscribed = computed(() => store.getters['user/isUserUnsubscribed']);

  return {
    createdDateUTC,
    currentDate,
    currentDateUTC,
    isActiveUser,
    isMetricSystem,
    isTrialUser,
    isUnsubscribed,
    measurementSystem,
    timeZone,
    user,
  };
};
