import { WorkoutStatus, ConnectionType } from '~/data/types';

export const HEADER_BACKGROUND_TYPES = {
  DEFAULT: 'default',
  BLUE: 'blue',
  GRADIENT: 'gradient',
  GREEN: 'green',
  ORANGE: 'orange',
  GRAY: 'gray',
  PINK: 'pink',
};

export const WORKOUT_STATUS_HEADER_BACKGROUND_TYPES_MAP = {
  [WorkoutStatus.Completed]: HEADER_BACKGROUND_TYPES.GREEN,
  [WorkoutStatus.CompletedMissed]: HEADER_BACKGROUND_TYPES.GRAY,
  [WorkoutStatus.CompletedPartially]: HEADER_BACKGROUND_TYPES.ORANGE,
  [WorkoutStatus.Future]: HEADER_BACKGROUND_TYPES.DEFAULT,
  [WorkoutStatus.Imported]: HEADER_BACKGROUND_TYPES.DEFAULT,
  [WorkoutStatus.Missed]: HEADER_BACKGROUND_TYPES.GRAY,
  [WorkoutStatus.Today]: HEADER_BACKGROUND_TYPES.BLUE,
  [WorkoutStatus.Unavailable]: HEADER_BACKGROUND_TYPES.GRAY,
};

export const ICONS = {
  bike: 'bike',
  comment: 'comment',
  event_recovery: 'event-recovery',
  event: 'flag',
  media: 'media',
  mobility: 'mobility',
  rest: 'rest',
  run_brick: 'run-brick',
  run: 'run',
  strength: 'strength',
  swim: 'swim',
  swimrun: 'swim-run',
};

export const ITEMS_PER_PAGE = 18;

export const MUTATIONS = {
  GET_LIST: 'GET_LIST',
  CLEAR_LIST: 'CLEAR_LIST',
  SET_LOADING: 'SET_LOADING',
  UPDATE_PAGINATION: 'UPDATE_PAGINATION',
};

export const POST_FILTER = {
  ALL_POSTS: 'authorOnly', // show all posts except workouts on the main tab
  FOLLOWED_ONLY: 'followedOnly',
  MY_ONLY: 'myOnly',
};

export const FEED_TYPES = [
  POST_FILTER.ALL_POSTS,
  POST_FILTER.FOLLOWED_ONLY,
  POST_FILTER.MY_ONLY,
];

export const FEED_SLIDER_TYPE = {
  RECOMMENDED: 'recommended',
  WORKOUTS: 'workouts',
};

export const FILTER_TYPE = {
  LEADERBOARD: 'leaderboard',
  USERS: 'users',
};

export const USERS_LIST_TYPE = {
  RECOMMENDED: 'recommended',
  USERS: 'users',
};

export const DAY_STATUS = {
  PAST: 'past',
  TODAY: 'today',
  FUTURE: 'future',
};

export const WEEK_STATUS = {
  PREVIOUS_WEEKS: 'previous',
  CURRENT_WEEK: 'current',
  NEXT_WEEK: 'next',
  AFTER_NEXT_WEEKS: 'after_next',
};

export const CONNECTION_TYPES = {
  [ConnectionType.TrainingPeaks]: 'TrainingPeaks',
  [ConnectionType.Strava]: 'Strava',
  [ConnectionType.Garmin]: 'Garmin Connect',
  [ConnectionType.Wahoo]: 'Wahoo',
  [ConnectionType.Zwift]: 'Zwift',
};

export const WORKOUTS_INFO = {
  sprint: {
    '4-7': {
      title: '<b>workouts</b> per week should <b>get you to the finish line</b> of your Sprint race.',
      list: [
        'Increase to 8-10 workouts per week to finish feeling strong.',
        'To be competitive, we recommend 11-14 workouts per week.',
      ],
    },
    '8-10': {
      title: '<b>workouts</b> per week should get you to the finish line <b>feeling strong</b> in your Sprint race.',
      list: [
        'Decrease to 4-7 workouts per week and you should get to the finish line.',
        'To be competitive, we recommend 11-14 workouts per week.',
      ],
    },
    '11-14': {
      title: '<b>workouts</b> per week should get you to the finish line <b>feeling strong</b> in your Sprint race.',
      list: [
        'Decrease to 4-7 workouts per week and you should get to the finish line.',
        'Decrease to 8-10 workouts per week and you should get to the finish line feeling strong.',
      ],
    },
  },
  olympic: {
    '4-7': {
      title: '<b>workouts</b> per week should <b>get you to the finish line</b> of your Olympic race.',
      list: [
        'Increase to 8-10 workouts per week to finish feeling strong.',
        'To be competitive, we recommend 11-14 workouts per week.',
      ],
    },
    '8-10': {
      title: '<b>workouts</b> per week should get you to the finish line <b>feeling strong</b> in your Olympic race.',
      list: [
        'Decrease to 4-7 workouts per week and you should get to the finish line.',
        'To be competitive, we recommend 11-14 workouts per week.',
      ],
    },
    '11-14': {
      title: '<b>workouts</b> per week should get you to the finish line <b>feeling competitive</b> in your Olympic race.',
      list: [
        'Decrease to 4-7 workouts per week and you should get to the finish line.',
        'Decrease to 8-10 workouts per week and you should get to the finish line feeling strong.',
      ],
    },
  },
  half_ironman: {
    '4-7': {
      title: '<b>workouts</b> per week will make finishing your Half Ironman <b>questionable</b>',
      list: [
        'Increase to 8-10 workouts per week and you should get you to the finish line.',
        'Increase to 11-14 workouts per week to finish feeling strong.',
      ],
    },
    '8-10': {
      title: '<b>workouts</b> per week should <b>get you to the finish line</b> of your Half Ironman race.',
      list: [
        'Decrease to 4-7 workouts per week and finishing will be questionable.',
        'Increase to 11-14 workouts per week to finish feeling strong.',
      ],
    },
    '11-14': {
      title: '<b>workouts</b> per week should get you to the finish line <b>feeling strong</b> in your Half Ironman race.',
      list: [
        'Decrease to 4-7 workouts per week and finishing will be questionable.',
        'Decrease to 8-10 workouts per week and you should get to the finish line.',
      ],
    },
  },
  ironman: {
    '4-7': {
      title: '<b>workouts</b> per week will make finishing your Ironman race <b>questionable</b>. ',
      list: [
        'Increase to 8-10 workouts per week and finishing will be questionable.',
        'Increase to 11-14 workouts per week to finish feeling strong.',
      ],
    },
    '8-10': {
      title: '<b>workouts</b> per week will make finishing your Ironman race <b>questionable</b>.',
      list: [
        'Decrease to 4-7 workouts per week and finishing will be questionable.',
        'Increase to 11-14 workouts per week to finish feeling strong.',
      ],
    },
    '11-14': {
      title: '<b>workouts</b> per week should get you to the finish line <b>feeling strong</b> in your Ironman race.',
      list: [
        'Decrease to 4-7 workouts per week and finishing will be questionable.',
        'Decrease to 8-10 workouts per week and finishing will be questionable.',
      ],
    },
  },
};

export const POST_IMAGE_SIZES = {
  large: '2048x2048',
  small: '600x600',
};

export const COMPLETION_STATUS_MANUAL = {
  POOR: 1,
  AVERAGE: 2,
  TARGET: 3,
};

export const COMPLETION_STATUS_AUTO = {
  TOO_LOW: 1,
  LOW: 2,
  TARGET: 3,
  HIGH: 4,
  TOO_HIGH: 5,
};

export const ZONE_TITLES = ['Easy Recovery', 'Endurance', 'Tempo', 'V02 Max', 'Fast'];

const ZONE_TYPE = [
  { title: 'Effort', subtitle: 'RPE', fieldName: 'rpe' },
  { title: 'Heart Rate', subtitle: 'bpm', fieldName: 'heartRate' },
  { title: 'Bike Power', subtitle: 'watts', fieldName: 'power' },
];

export const ZONE_TYPE_IMPERIAL = [
  ...ZONE_TYPE,
  { title: 'Run Pace', subtitle: 'min/mi', fieldName: 'pace' },
];

export const ZONE_TYPE_METRIC = [
  ...ZONE_TYPE,
  { title: 'Run Pace', subtitle: 'min/km', fieldName: 'pace' },
];

export const ZONE_EMPTY_STATE = {
  rpe: '',
  heartRate: 'Use Effort (RPE), Power or Pace',
  power: 'No FTP provided. Use Effort (RPE) or HR',
  pace: 'No pacing information provided. Use Effort (RPE) or HR',
};

export const WORKOUT_STATS_LABELS = {
  distance: 'Total Distance',
  duration_moving: 'Moving Time',
  duration_total: 'Total Time',
  pace_avg: 'Avg Pace',
  power_avg: 'Avg Power',
  power_max: 'Max Power',
  power_normalized: 'Normalized',
  speed_avg: 'Avg Speed',
  speed_max: 'Max Speed',
  hr_avg: 'Avg HR',
  hr_max: 'Max HR',
  time_in_hr_zones: 'HR',
  time_in_pace_zones: 'Pace',
  time_in_power_zones: 'Power',
};

export const SKILLS = [
  { name: 'Beginner', desc: 'You just want to finish or you don’t have much time to train.' },
  { name: 'Intermediate', desc: 'You want to finish feeling strong and train a moderate amount.' },
  { name: 'Advanced', desc: 'You want to compete and don\'t mind big training hours.' },
];

export const STEP_KEYS = {
  SIGNUP_LANDING: 'SIGNUP_LANDING',
  SIGNUP_SPORT: 'SIGNUP_SPORT',
  SIGNUP_EXPERIENCE: 'SIGNUP_EXPERIENCE',
  SIGNUP_BEGINNER: 'SIGNUP_BEGINNER',
  SIGNUP_METHOD: 'SIGNUP_METHOD',
  SIGNUP_SUBSCRIBED: 'SIGNUP_SUBSCRIBED',
  SIGNUP_LOADING: 'SIGNUP_LOADING',
};

export const REMEMBER_ME_DAYS = 7;

export const COUNTDOWN_MIN_MONTHS = 3;
export const COUNTDOWN_MIN_WEEKS = 2;
