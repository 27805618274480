import { render, staticRenderFns } from "./TrainingPlanItemNotesPlaceholder.vue?vue&type=template&id=543dabde&scoped=true&"
import script from "./TrainingPlanItemNotesPlaceholder.vue?vue&type=script&lang=ts&"
export * from "./TrainingPlanItemNotesPlaceholder.vue?vue&type=script&lang=ts&"
import style0 from "./TrainingPlanItemNotesPlaceholder.vue?vue&type=style&index=0&id=543dabde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543dabde",
  null
  
)

export default component.exports