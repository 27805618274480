import { getCookie } from '@/utils/cookieStorage';

export const trackPageView = () => {
  try {
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
  } catch {}
};

export const trackInitiateCheckout = () => {
  try {
    // eslint-disable-next-line no-undef
    fbq('track', 'InitiateCheckout');

    // eslint-disable-next-line no-undef
    gtag('event', 'begin_checkout', {
      event_category: 'app',
      event_label: 'Begin App Sign Up',
    });
  } catch {}
};

export const trackSignUp = () => {
  try {
    // eslint-disable-next-line no-undef
    fbq('track', 'StartTrial', {
      value: 0.00,
      currency: 'USD',
      predicted_ltv: 0.00,
    });

    // eslint-disable-next-line no-undef
    gtag('event', 'sign_up', {
      event_category: 'app',
      event_label: 'App Sign Up Complete',
    });
  } catch {}
};

export const trackPurchase = () => {
  try {
    // eslint-disable-next-line no-undef
    fbq('track', 'Purchase', {
      value: 57.00,
      currency: 'USD',
    });

    // eslint-disable-next-line no-undef
    gtag('event', 'purchase', {
      event_category: 'app',
      event_label: 'Trial User Converted',
      value: 57.00,
      currency: 'USD',
    });
  } catch {}
};

export const shouldTrackSignUp = () => {
  const result = getCookie('auth.subscription');
  return !result;
};

export const shouldTrackPurchase = () => {
  const result = getCookie('auth.subscription');
  return result === 'trialing' || result === 'canceled';
};
