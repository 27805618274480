<template>
  <nuxt />
</template>

<script>
export default {
  name: 'Default',
  middleware({ store, redirect }) {
    if (!store.state.auth.loggedIn) {
      redirect('https://mymottiv.com');
    }
  },
};
</script>
