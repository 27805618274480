export const state = () => ({
  list: [],
});

// there are a lot of modules inside the platform when user can follows someone
// (sidebars, sliders), when user trigger follow action
// all similar modules should be updated
const relationshipsModules = (rootState) => {
  return {
    leaderboard: rootState.leaderboard.list.length,
    recommended: rootState.recommended.list.length,
    users: rootState.users.list.length,
    notifications: rootState.notifications.list.length || rootState.notifications.dropdownList.length,
    sliders: rootState.sliders.recommended.length,
  };
};

export const actions = {
  async FOLLOW_USER({ commit, rootState }, action) {
    try {
      const { data } = await this.$axios.$post('/api/follow', { user_id: action.userId });
      const modules = relationshipsModules(rootState);
      await this.$auth.fetchUser();

      Object.keys(modules).forEach((moduleName) => {
        if (modules[moduleName] > 0) {
          commit(`${moduleName}/SET_RELATIONSHIP`, { data }, { root: true });
        }
      });
    } catch {
      // Do nothing
    }
  },

  async UNFOLLOW_USER({ commit, rootState }, action) {
    try {
      await this.$axios.$delete(`/api/follow/${action.ownFollowee.id}`);
      const modules = relationshipsModules(rootState);
      await this.$auth.fetchUser();

      Object.keys(modules).forEach((key) => {
        if (modules[key] > 0) {
          commit(`${key}/REMOVE_RELATIONSHIP`, { userId: action.ownFollowee.user_id }, { root: true });
        }
      });
    } catch {
      // Do nothing
    }
  },
};

export const mutations = {
};
