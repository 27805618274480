import { getCookie } from '@/utils/cookieStorage';

export const getReferrer = () => {
  return localStorage.getItem('referrer');
};

export const setReferrer = (route) => {
  let referrer = document.referrer;

  if (getCookie('referrer')) {
    referrer = getCookie('referrer');
  }

  if (route?.query?.ref) {
    localStorage.setItem('referrer', route.query.ref);
  }

  if (referrer && referrer !== '') {
    localStorage.setItem('referrer', referrer);
  }
};
