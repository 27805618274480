export enum Gender {
  Male = 1,
  Female = 2,
};

export enum MeasurementSystem {
  Metric = 1,
  Imperial = 2,
};

export type UserNotificationSettings = {
  isCommentNotificationOn: boolean,
  isFiverNotificationOn: boolean,
  isIncompleteWorkoutNotificationOn: boolean,
  isScheduledWorkoutNotificationOn: boolean,
};

export type UserPrivacySettings = {
  isAutoShareWorkoutsOn: boolean,
  isHideLocationOn: boolean,
};

export type UserSettings = UserNotificationSettings & UserPrivacySettings;

export enum UserSubscriptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  Cancelled = 'cancelled',
  PastDue = 'past_due',
};

export enum UserSubscriptionType {
  Stripe = 1,
  AppleIAP = 2,
};

export type UserSubscription = {
  next_payment_date: string,
  status: UserSubscriptionStatus,
  stripe_plan_id: string | null,
  stripe_plan_title: string | null,
  stripe_plan_summary: string | null,
  stripe_plan_badge_content: string | null,
  type: UserSubscriptionType,
  stripe_scheduled_plan_id: string | null,
  stripe_scheduled_plan_title: string | null,
};

// Properties must match what's returned from the API as they're stored directly in $auth.user.data
export type User = {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  is_email_confirmed: boolean,
  card_brand: string | null,
  card_last_four: string | null,
  city: string,
  country: string,
  country_code: string,
  weight: number | null,
  gender: Gender | null,
  timezone: string,
  avatar: string | null,
  measurement_system: MeasurementSystem,
  has_current_password: boolean,
  subscription: UserSubscription | null,
  created_at: string,
  unread_notifications_count: number,
  has_beta_access: boolean | null,
};
