import isArray from 'lodash/isArray';

export const state = () => {
  return {
    plans: [],
    isLoading: true,
  };
};

export const actions = {
  async getPlans({ commit }) {
    try {
      commit('SET_IS_LOADING', true);
      const response = await this.$axios.get('/api/subscription-plans');
      commit('SET_PLANS', response?.data?.data || []);
      commit('SET_IS_LOADING', false);
    } catch {}
  },
};

export const mutations = {
  SET_PLANS(state, payload) {
    if (isArray(payload) && payload.length) {
      state.plans = payload.map((item) => {
        return {
          id: item.plan_id,
          isDiscountable: item.is_discountable,
          summary: item.summary,
          title: item.title,
        };
      });
    } else {
      state.plans = [];
    }
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
};
