import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/users/my-uploads',
});

export const actions = {
  ...commonStore.actions,
};

export const mutations = {
  ...commonStore.mutations,
};
