import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, confirmed, size, min_value, max_value, numeric, min } from 'vee-validate/dist/rules';
import { isValid } from 'date-fns';

extend('date', {
  validate(value) {
    return value ? isValid(value) : false;
  },
  params: ['length'],
  message: fieldName => `The format of ${fieldName} is invalid.`,
});

extend('email', {
  ...email,
  message: 'Email address is not a valid email address',
});

extend('required', {
  ...required,
  message: (fieldName) => {
    const field = fieldName === '{field}' ? 'Field' : fieldName;
    return `${field} cannot be blank`;
  },
});

extend('min_value', {
  // eslint-disable-next-line camelcase
  ...min_value,
  message: (fieldName, params) => {
    return `${fieldName} must be no less than ${params.min}`;
  },
});

extend('max_value', {
  // eslint-disable-next-line camelcase
  ...max_value,
  message: (fieldName, params) => {
    return `${fieldName} must be no greater than ${params.max}`;
  },
});

extend('numeric', {
  ...numeric,
  message: (fieldName) => {
    return `${fieldName} must be a whole number`;
  },
});

extend('termsAndConditions', (value) => {
  return value || 'You must agree to the Terms and Conditions';
});

extend('accountEmails', (value) => {
  return value || 'We need to be able to send you emails to set up and manage your account';
});

extend('confirmed', {
  ...confirmed,
  message: '{_field_} does not match',
});

extend('image_resolution', {
  validate(files, [width, height]) {
    const validateImage = (file, width, height) => {
      const URL = window.URL || window.webkitURL;

      return new Promise((resolve) => {
        const image = new Image();
        image.onerror = () => resolve({ valid: false });
        image.onload = () => resolve({
          valid: image.width >= Number(width) && image.height >= Number(height), // only change from official rule
        });

        image.src = URL.createObjectURL(file);
      });
    };

    const list = [];
    for (let i = 0; i < files.length; i++) {
      // if file is not an image, reject.
      if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
        return false;
      }
      list.push(files[i]);
    }

    return validateImage(list[0], width, height).then(param => param.valid);
  },
  message: (_, customParams) => {
    return `Minimum size is ${customParams[0]}x${customParams[1]}px`;
  },
});

extend('size', {
  ...size,
  message: (_, param) => {
    return `Maximum photo size is ${param.size / 1000}MB`;
  },
});

extend('weight', {
  validate(value) {
    return value > 0;
  },
  message: 'Please enter a valid body weight',
});

extend('password', {
  validate(value) {
    // at least one number, one lowercase and one uppercase letter
    // at least eight characters and one special symbol
    const passwordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return passwordValidation.test(value);
  },
  message: 'Password must be at least 8 characters long, including uppercase, lowercase and number',
});

extend('duration', {
  validate(value) {
    const parts = value.split(':');
    const hh = Number(parts[0]);
    return !isNaN(hh);
  },
  message: 'hours is required',
});

extend('min', {
  ...min,
  message: (fieldName, param) => {
    return `${fieldName} field must be at least ${param.length} characters`;
  },
});

extend('card_name', {
  validate(value) {
    const names = value.split(' ');
    return names?.filter(val => val)?.length > 1;
  },
  message: 'Please enter your first and last name.',
});

extend('race_event', {
  validate(value) {
    return !!value?.id && !!value?.key;
  },
  message: (fieldName) => {
    return `${fieldName} cannot be blank`;
  },
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
