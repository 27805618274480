/**
 * Gets the shareable user data for third party tools.
 *
 * @param {object} user
 * @returns {object}
 */
export default function(user) {
  const data = {
    birthday: user.birthday,
    city: user.city,
    country: user.country,
    timezone: user.timezone,
    'measurement-system': user.measurement_system === 1 ? 'metric' : 'imperial',
    'has-confirmed-email': user.is_email_confirmed === true,
  };

  if (user.weight) {
    data.weight = user.weight;
  }

  if (user.subscription) {
    data['is-active'] = user.subscription?.status === 'active';
    data['is-cancelled'] = false;
    data['is-trial'] = user.subscription?.status === 'trialing';
    data['subscription-id'] = user.subscription?.stripe_plan_id;
  } else {
    data['is-cancelled'] = true;
  }

  if (user.nextEvent) {
    data['has-next-event'] = true;
    data['next-event-date'] = user.nextEvent?.event_date;
  } else {
    data['has-next-event'] = false;
  }

  return data;
};
