const REDIRECT_MAP = {
  '/my-account/change-password': '/account/change-password',
  '/my-account/membership': '/account/billing',
  '/my-account/profile': '/account/profile',
  '/my-training/connections': '/account/connections',
  '/my-training/races': '/training/races',
  '/my-training/zones': '/training/zones',
};

export default async function({ env, redirect, route, store, $auth }) {
  const isUserLoggedIn = store.state.auth.loggedIn;
  const isUnsubscribed = store.getters['user/isUserUnsubscribed'];

  if (
    !route.path.includes('impersonate')
    && env.BASE_BETA_URL
    && !env.IS_BETA
    && isUserLoggedIn
    && store.state.auth.user?.data?.has_beta_access
  ) {
    const token = $auth.$storage.getCookie('_token.local');
    await $auth.setUserToken(token);
    redirect(302, env.BASE_BETA_URL + '/impersonate/?token=' + token);
    return;
  }

  // Legacy route support for iOS
  if (REDIRECT_MAP[route.path]) {
    redirect(REDIRECT_MAP[route.path]);
    return;
  }

  // Legacy route support for iOS
  if (route.path === '/terms-and-conditions') {
    redirect('https://mymottiv.com/terms-and-conditions');
    return;
  }

  // Legacy route support for iOS
  if (route.path.startsWith('/my-training/workout-settings')) {
    redirect('/training/settings');
    return;
  }

  // Legacy support for non-updated ext links
  if (route.path.startsWith('/onboarding')) {
    redirect('/register');
    return;
  }

  if (isUserLoggedIn && isUnsubscribed) {
    if (!route.path.includes('rejoin')) {
      redirect('/rejoin');
    }
    return;
  }

  if ((route.path === '/' || route.path === '/login/' || route.path === '/login') && isUserLoggedIn) {
    redirect('/dashboard');
  }
};
