import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { State } from '~/data/types/store';
import { getWorkoutDetails, getWorkoutStatus, getWorkoutType } from '~/data/training/get-training-plan-details';
import { useUser } from '~/data/account/use-user';

type UpdateData = {
  notes: string | null,
  completionStatus: number | null,
};

export const useTrainingPlanItem = () => {
  const store = useStore<State>();
  const { isMetricSystem, timeZone } = useUser();
  const isLoading = computed(() => store.state.trainingPlanItem.isLoading);
  const isSavingId = computed(() => store.state.trainingPlanItem.isSavingId);
  const trainingPlanItem = computed(() => store.state.trainingPlanItem.data);
  const isEvent = computed(() => trainingPlanItem.value?.workout.categoryKey === 'event');

  const status = computed(() => trainingPlanItem.value
    ? getWorkoutStatus(trainingPlanItem.value, timeZone.value)
    : null,
  );

  const workoutType = computed(() => trainingPlanItem.value
    ? getWorkoutType(trainingPlanItem.value)
    : '',
  );

  const workoutDetails = computed(() => trainingPlanItem.value
    ? getWorkoutDetails(
        trainingPlanItem.value.workout.duration_min,
        trainingPlanItem.value.workout.duration_max,
        trainingPlanItem.value.workout.distance_min,
        trainingPlanItem.value.workout.distance_max,
        trainingPlanItem.value.workout.categoryKey,
        isMetricSystem.value,
      )
    : null,
  );

  const clearData = () => {
    store.dispatch('trainingPlanItem/CLEAR_DATA');
  };

  const getTrainingPlanItem = async(id: number) => {
    await store.dispatch('trainingPlanItem/GET_TRAINING_PLAN_ITEM', id);
  };

  const updateTrainingPlanItem = async(workoutId: number, data: UpdateData, forceShare: boolean = false) => {
    store.commit('trainingPlanItem/SET_IS_LOADING', true);
    store.commit('trainingPlanItem/SET_IS_SAVING_ID', workoutId);

    try {
      const oldStatus = trainingPlanItem.value
        ? `${trainingPlanItem.value.completion_status_manual}-${trainingPlanItem.value.completion_status_automatic}`
        : null;

      const payload: { [key: string]: any } = {
        id: workoutId,
        data: {
          completed_note: data.notes,
        },
      };

      if (forceShare) {
        payload.data.autoShare = true;
      }

      if (data.completionStatus) {
        payload.data.completion_status_manual = data.completionStatus;
      }

      const newTrainingPlan = await store.dispatch('trainingPlanItem/UPDATE_TRAINING_PLAN_ITEM', payload);

      store.commit('trainingPlan/SET_TRAINING_PLAN_ITEM', newTrainingPlan);
      store.commit('dashboard/SET_TRAINING_PLAN_ITEM', newTrainingPlan);

      const newStatus = newTrainingPlan ? `${newTrainingPlan.completion_status_manual}-${newTrainingPlan.completion_status_automatic}` : null;
      if (oldStatus !== newStatus) {
        store.commit('trainingPlan/CLEAR_CALENDAR');
      }

      store.commit('trainingPlanItem/SET_IS_SAVING_ID', null);
      return true;
    } catch {
      store.commit('trainingPlanItem/SET_IS_SAVING_ID', null);
      return false;
    }
  };

  return {
    clearData,
    getTrainingPlanItem,
    isEvent,
    isLoading,
    isSavingId,
    status,
    trainingPlanItem,
    updateTrainingPlanItem,
    workoutDetails,
    workoutType,
  };
};
