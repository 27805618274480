import findIndex from 'lodash/findIndex';
import Vue from 'vue';

export const state = () => ({
  recommended: [],
  workouts: [],
});

export const actions = {
  async GET_RECOMMENDED({ commit, state }, action) {
    const { data } = await this.$axios.$get('/api/users/recommended', {
      params: action.params,
    });
    commit('SET_RECOMMENDED', data);
  },

  async GET_WORKOUTS({ commit, state }, action) {
    const { data } = await this.$axios.$get('/api/posts', {
      params: action.params,
    });
    commit('SET_WORKOUTS', data);
  },
};

export const mutations = {
  SET_RECOMMENDED(state, data) {
    state.recommended = data;
  },
  SET_WORKOUTS(state, data) {
    state.workouts = data;
  },
  ADD_REACTION(state, data) {
    const workout = state.workouts.find(x => x.id === data.modelId);

    if (workout) {
      workout.ownReaction = data.data;
    }
  },
  REMOVE_REACTION(state, { modelId }) {
    state.workouts.find(x => x.id === modelId).ownReaction = null;
  },
  SET_RELATIONSHIP(state, { data }) {
    const postIndex = findIndex(state.recommended, { id: data.user_id });
    Vue.set(state.recommended, postIndex, {
      ...state.recommended[postIndex],
      ownFollowee: data,
    });
  },
  REMOVE_RELATIONSHIP(state, action) {
    const obj = state.recommended.find(x => x?.id === action.userId);
    if (obj) { obj.ownFollowee = null; }
  },
};
