var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({staticClass:"form-group form-group--input",attrs:{"mode":"lazy","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.label)?_c('label',{staticClass:"mb-1 font-sm d-flex justify-content-between",class:{ 'w-100': !!_vm.$slots['append'] },attrs:{"for":_vm.name}},[_c('span',{class:{ 'bg-light text-light': _vm.isLoading }},[_vm._v("\n      "+_vm._s(_vm.label)+"\n      "),(_vm.label && _vm.required)?_c('span',[_vm._v("*")]):_vm._e()]),_vm._v(" "),_vm._t("label-append")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"position-relative",class:{ 'input-group': !!_vm.$slots['append'] }},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalModel),expression:"internalModel"}],key:_vm.name,staticClass:"form-control rounded",class:{
        'is-invalid': errors && errors.length,
        'placeholder': _vm.isLoading,
      },attrs:{"id":_vm.name,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.isLoading ? '' : _vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.internalModel)?_vm._i(_vm.internalModel,null)>-1:(_vm.internalModel)},on:{"input":function($event){return _vm.$emit('input', $event.target.value, _vm.name)},"blur":function($event){return _vm.$emit('blur', $event.target.value, _vm.name)},"change":function($event){var $$a=_vm.internalModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.internalModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.internalModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.internalModel=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalModel),expression:"internalModel"}],key:_vm.name,staticClass:"form-control rounded",class:{
        'is-invalid': errors && errors.length,
        'placeholder': _vm.isLoading,
      },attrs:{"id":_vm.name,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.isLoading ? '' : _vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.internalModel,null)},on:{"input":function($event){return _vm.$emit('input', $event.target.value, _vm.name)},"blur":function($event){return _vm.$emit('blur', $event.target.value, _vm.name)},"change":function($event){_vm.internalModel=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalModel),expression:"internalModel"}],key:_vm.name,staticClass:"form-control rounded",class:{
        'is-invalid': errors && errors.length,
        'placeholder': _vm.isLoading,
      },attrs:{"id":_vm.name,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.isLoading ? '' : _vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.internalModel)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.internalModel=$event.target.value},function($event){return _vm.$emit('input', $event.target.value, _vm.name)}],"blur":function($event){return _vm.$emit('blur', $event.target.value, _vm.name)}}}),_vm._v(" "),(_vm.$slots['append'])?_c('div',{staticClass:"input-group-append align-items-center"},[_c('span',{staticClass:"input-group-text rounded p-0 d-block flex"},[_vm._t("append")],2)]):_vm._e(),_vm._v(" "),(errors && errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n      "+_vm._s(errors[0])+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isSaving)?_c('loading-block',{attrs:{"sm":""}}):_vm._e()],1),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"form-text text-muted font-sm"},[_vm._v("\n    "+_vm._s(_vm.description)+"\n  ")]):_vm._e()]}}],null,true)},'validation-provider',{ name: _vm.name, vid: _vm.vid, rules: _vm.rules },false))}
var staticRenderFns = []

export { render, staticRenderFns }