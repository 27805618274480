import { format, isAfter, isBefore, isSameDay, parse } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export enum DateFormat {
  Date = 'yyyy-MM-dd',
  DateTime = 'yyyy-MM-dd HH:mm:ss',
  DateTimeLocal = "yyyy-MM-dd'T'HH:mm:ss",
  Human = 'MMM d, yyyy',
  ISO = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
};

export const formatDate = (dateString: string, dateFormat: DateFormat = DateFormat.Date) => {
  const date = parse(dateString, dateFormat, new Date());
  return format(date, DateFormat.Human);
};

export const isDateBetweenDates = (date: Date, firstDate: Date, secondDate: Date): boolean => {
  return isSameDay(date, firstDate)
      || isSameDay(date, secondDate)
      || (isAfter(date, firstDate) && isBefore(date, secondDate));
};

export const getUTCDate = (dateString: string, timeZone: string) => {
  const dateStringInTimeZone = formatInTimeZone(new Date(dateString), 'UTC', DateFormat.Date);
  return zonedTimeToUtc(dateStringInTimeZone, timeZone);
};
