import loadScript from '~/helpers/loadScript';

function loadWidget(id) {
  return loadScript.load(
    `https://widget.freshworks.com/widgets/${id}.js`,
    {
      async: 'true',
    },
  );
}

export default function(store) {
  let isLoaded = !!window.FreshworksWidget;

  const launch = () => isLoaded && window.FreshworksWidget('open');

  const show = () => isLoaded && window.FreshworksWidget('show', 'launcher');

  const hide = () => isLoaded && window.FreshworksWidget('hide', 'launcher');

  /**
   * @param {object} userData
   */
  const identifyUser = (userData) => {
    isLoaded && window.FreshworksWidget('identify', 'ticketForm', {
      name: `${userData?.first_name} ${userData?.last_name}`,
      email: userData.email,
    });
  };

  const resetIdentity = () => {
    isLoaded && window.FreshworksWidget('identify', 'ticketForm', {
      name: '',
      email: '',
    });
  };

  const initialize = async() => {
    if (isLoaded || !process.env.FRESHWORKS_ID) {
      return;
    }

    window.fwSettings = { widget_id: process.env.FRESHWORKS_ID };
    /* eslint-disable */
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
    /* eslint-enable */

    isLoaded = await loadWidget(process.env.FRESHWORKS_ID)
      .then(() => true)
      .catch(() => false);
  };

  if (store.state.auth.user?.data) {
    const initializeForUser = async() => {
      await initialize();
      identifyUser(store.state.auth.user.data);
    };
    initializeForUser();
  }

  store.watch(
    (state) => {
      return state.auth.user?.data;
    },
    async(userData) => {
      if (!isLoaded) {
        await initialize();
      }

      if (userData) {
        identifyUser(userData);
        show();
      } else {
        resetIdentity();
        hide();
      }
    },
  );

  return { launch };
}
