import isArray from 'lodash/isArray';
import { getReferrer } from '~/helpers/referrer';

const DEFAULT_DATA = {
  experienceLevel: '',
  isLoading: true,
  signupMethod: '',
  skillLevels: {},
  sportId: null,
  sports: [],
};

export const state = () => {
  return DEFAULT_DATA;
};

export const actions = {
  async getSports({ commit }) {
    try {
      commit('SET_IS_LOADING', true);
      const response = await this.$axios.get('/api/sports');
      commit('SET_SPORTS', response?.data?.data || []);
      commit('SET_IS_LOADING', false);
    } catch {}
  },
  async signUp(_, data) {
    return await this.$axios.post('/api/users/signup', data);
  },
  async setAnalytic({ getters }, data) {
    const params = {
      ...data,
      referrer: getReferrer(),
      sport_key: getters?.chosenSport?.key,
    };
    return await this.$axios.post('/api/users/signup/progress', params);
  },
};

export const mutations = {
  CLEAR_ONBOARDING(state) {
    const sports = state.sports;
    state = {
      ...DEFAULT_DATA,
      sports,
    };
  },
  SET_ONBOARDING(state, payload) {
    state.experienceLevel = payload.experienceLevel || '';
    state.skillLevels = payload.skillLevels || {};
    state.isLoading = false;
    state.signupMethod = payload.signupMethod || '';
    state.sportId = payload.sportId || null;
    state.sports = payload.sports || [];
  },
  SET_SKILL_LEVEL(state, payload) {
    state.skillLevels[payload.key] = payload.result;
  },
  SET_SPORTS(state, payload) {
    if (isArray(payload) && payload.length) {
      state.sports = payload.map((sport) => {
        return {
          id: sport.id,
          title: sport.title,
          athleteTitle: sport.athlete_title,
          key: sport.key,
          hasSwimOnramp: sport.has_swim_onramp,
          hasBikeOnramp: sport.has_bike_onramp,
          hasRunOnramp: sport.has_run_onramp,
        };
      });
    } else {
      state.sports = [];
    }
  },
  SET_CHOSEN_SPORT_ID(state, id) {
    state.sportId = id;
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  SET_EXPERIENCE_LEVEL(state, value) {
    state.experienceLevel = value;
  },
  SET_SIGNUP_METHOD(state, value) {
    state.signupMethod = value;
  },
};

const chosenSport = (state) => {
  return state.sports?.find(sport => sport.id === state.sportId) || null;
};

export const getters = {
  chosenSport,
  experienceSubSteps(state) {
    const sport = chosenSport(state);
    let subSteps = 0;

    if (!sport || state.experienceLevel !== 'Beginner') {
      return subSteps;
    }

    if (sport.hasSwimOnramp) {
      subSteps += 1;
    }
    if (sport.hasBikeOnramp) {
      subSteps += 1;
    }
    if (sport.hasRunOnramp) {
      subSteps += 1;
    }

    return subSteps;
  },
};
