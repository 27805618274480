import getUserShareableData from './getUserShareableData';

/**
 * Stonly init and helper functions.
 *
 * @param {function} module
 * @param {string} id
 * @param {object} store
 * @returns
 */
export default function(module, id, store) {
  let initialized = false;

  const initialize = () => {
    if (!initialized) {
      module('init', id);
      initialized = true;
    }
  };

  /**
   * To send a custom event to Stonly, create it first in Stonly via the User data menu
   * and call the track function using the event key provided by Stonly.
   *
   * @param {string} key
   */
  const track = (key) => {
    module('track', key);
  };

  /**
   * To identify your users, call the module’s identify function with the desired user ID.
   * When it is possible, we recommend using anonymous user ID for privacy reasons.
   *
   * To send a custom property to Stonly, create it first in Stonly via the User data menu
   * and call the identify function using the property key provided by Stonly.
   *
   * @param {object} user
   */
  const identifyUser = (user) => {
    const userData = getUserShareableData(user);
    module('identify', user.id.toString(), userData);
  };

  /**
   * @param {object} user
   */
  const initializeWithUser = (user) => {
    initialize();
    identifyUser(user);
  };

  // If a user exists, initialize with user details
  if (store.state.auth.user?.data) {
    initializeWithUser(store.state.auth.user.data);
  }

  store.watch(
    (state) => {
      return state.auth.user?.data;
    },
    (user) => {
      if (user) {
        initializeWithUser(user);
      } else if (!user) {
        initialized = false;
      }
    },
  );

  return { track };
}
