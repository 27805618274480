import { useStore } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import { addDays, addWeeks, addYears, differenceInCalendarDays, endOfISOWeek, format, isAfter, isBefore, isSameDay, parse, startOfISOWeek, subDays, subWeeks } from 'date-fns';

import { getWorkoutDetails, getWorkoutStatus } from '~/data/training/get-training-plan-details';
import { getImportedActivityDetails } from '~/data/training/get-imported-activity-details';
import { State } from '~/data/types/store';
import { CalendarActivity, CalendarDayStatus, CalendarWeek, ImportedActivity, TrainingPlanFocus, TrainingPlanItem, TrainingPlanPreview, WorkoutStatus } from '~/data/types';
import { useUser } from '~/data/account/use-user';
import { DateFormat } from '~/helpers/date';

export const useTrainingPlan = () => {
  const store = useStore<State>();
  const { createdDateUTC, currentDate, currentDateUTC, isMetricSystem, timeZone } = useUser();

  const isLoadingFocus = computed(() => store.state.trainingPlan.isLoadingFocus);
  const isLoadingPreview = computed(() => store.state.trainingPlan.isLoadingPreview);
  const isLoadingTrainingPlan = computed(() => store.state.trainingPlan.isLoadingTrainingPlan);
  const calendar = computed(() => store.state.trainingPlan.calendar);
  const prevCalendar = computed(() => store.state.trainingPlan.prevCalendar);
  const lastFocusIndex = computed(() => store.state.trainingPlan.lastFocusIndex);
  const hasLoadedEverythingInTheFuture = computed(() => store.state.trainingPlan.hasLoadedEverythingInTheFuture);
  const hasLoadedEverythingInThePast = computed(() => store.state.trainingPlan.hasLoadedEverythingInThePast);
  const currentFocus = computed(() => {
    const currentFocuses = store.state.trainingPlan.focus.filter(item => item.isCurrent);
    return currentFocuses.length ? currentFocuses[0] : null;
  });
  const focusList = computed(() => store.state.trainingPlan.focus);

  const getImportedActivities = async(fromDate: Date, toDate: Date) => {
    await store.dispatch('trainingPlan/GET_IMPORTED_ACTIVITIES', {
      fromDate: format(fromDate, DateFormat.Date),
      toDate: format(toDate, DateFormat.Date),
    });
  };

  const getInitialFocus = async() => {
    if (store.state.trainingPlan.focus.length) {
      return;
    }
    const focusFromDate = startOfISOWeek(currentDateUTC.value);
    const focusToDate = addYears(endOfISOWeek(currentDateUTC.value), 1);
    await store.dispatch('trainingPlan/GET_FOCUS', {
      fromDate: format(focusFromDate, DateFormat.Date),
      toDate: format(focusToDate, DateFormat.Date),
      timeZone: timeZone.value,
    });
  };

  const getInitialCalendar = async() => {
    try {
      const hasFocus = !!store.state.trainingPlan.focus.length;
      const hasTrainingPlan = !!Object.keys(store.state.trainingPlan.trainingPlan).length;

      if (!hasFocus || !hasTrainingPlan) {
        const planFromDate = startOfISOWeek(currentDateUTC.value);
        const planToDate = addWeeks(endOfISOWeek(currentDateUTC.value), 1);

        await Promise.all([
          store.dispatch('trainingPlan/GET_TRAINING_PLAN', {
            fromDate: format(planFromDate, DateFormat.Date),
            toDate: format(planToDate, DateFormat.Date),
          }),
          getInitialFocus(),
        ]);

        getImportedActivities(planFromDate, planToDate).then(() => {
          updateCalendar();
        });
      }

      store.dispatch('trainingPlan/UPDATE_LAST_FOCUS_INDEX', store.state.trainingPlan.focus.length ? 0 : -1);
      updateCalendar();
    } catch {
      // Do nothing
    }
  };

  const getNextCalendar = async() => {
    if (!calendar.value.length || hasLoadedEverythingInTheFuture.value) {
      return;
    }

    let lastVisibleCalendar = getLastCalendar();

    // Determine if we need to display the next focus
    // @ts-ignore date isn't considered a date
    const isEntireFocusVisible = !!lastVisibleCalendar.day && isSameDay(lastVisibleCalendar.calendar.focus.endDate, lastVisibleCalendar.day.date);

    if (isEntireFocusVisible) {
      store.dispatch('trainingPlan/UPDATE_LAST_FOCUS_INDEX', lastFocusIndex.value + 1);
      // @ts-ignore date isn't considered a date
      await loadMorePreviewsIfNecessary(lastVisibleCalendar.day.date);
    } else {
      // @ts-ignore date isn't considered a date
      await loadMorePreviewsIfNecessary(lastVisibleCalendar.calendar.focus.endDate);
    }

    updateCalendar();

    // If last calendar item matches last available focus date
    const endDateOfLastFocus = store.state.trainingPlan.focus[store.state.trainingPlan.focus.length - 1].endDate;
    lastVisibleCalendar = getLastCalendar();
    // @ts-ignore date isn't considered a date
    if (isSameDay(lastVisibleCalendar.day.date, endDateOfLastFocus)) {
      store.dispatch('trainingPlan/UPDATE_HAS_LOADED_EVERYTHING_IN_THE_FUTURE', true);
    }
  };

  const getLastCalendar = () => {
    const lastVisibleCalendar = calendar.value[lastFocusIndex.value];
    const lastVisibleCalendarWeek = !!lastVisibleCalendar && lastVisibleCalendar.weeks[lastVisibleCalendar.weeks.length - 1];
    const lastVisibleCalendarDay = !!lastVisibleCalendarWeek && lastVisibleCalendarWeek.days[lastVisibleCalendarWeek.days.length - 1];
    return {
      calendar: lastVisibleCalendar,
      week: lastVisibleCalendarWeek,
      day: lastVisibleCalendarDay,
    };
  };

  const getPrevCalendar = async() => {
    if (!store.state.trainingPlan.focus.length || hasLoadedEverythingInThePast.value) {
      return;
    }

    const toDate = subDays(store.state.trainingPlan.focus[0].startDate, 1);
    const fromDate = prevCalendar.value.length // @ts-ignore date is not a Date
      ? startOfISOWeek(subWeeks(subDays(prevCalendar.value[0].days[0].date, 1), 1))
      : startOfISOWeek(subWeeks(toDate, 1));

    await store.dispatch('trainingPlan/GET_TRAINING_PLAN', {
      fromDate: format(fromDate, DateFormat.Date),
      toDate: format(toDate, DateFormat.Date),
    });

    getImportedActivities(fromDate, toDate).then(() => {
      updatePrevCalendar(diff, fromDate);
    });

    const diff = differenceInCalendarDays(toDate, fromDate) + 1;
    updatePrevCalendar(diff, fromDate);
  };

  const loadMorePreviewsIfNecessary = async(date: Date) => {
    let fromDate: Date;
    let toDate: Date;
    let shouldLoadMore = false;
    const previewKeys = Object.keys(store.state.trainingPlan.preview);
    if (!previewKeys.length) {
      const planToDate = addWeeks(endOfISOWeek(currentDateUTC.value), 1);
      fromDate = addDays(planToDate, 1);
      toDate = addWeeks(endOfISOWeek(fromDate), 3);
      shouldLoadMore = true;
    } else {
      const lastPreviewKey = previewKeys[previewKeys.length - 1];
      const lastPreviewDate = parse(lastPreviewKey, DateFormat.Date, currentDateUTC.value);
      // @ts-ignore no overload matches call - apparently lastPreviewDate is not a date?
      fromDate = addDays(lastPreviewDate, 1);
      toDate = addWeeks(endOfISOWeek(fromDate), 8);
      shouldLoadMore = isBefore(lastPreviewDate, date) || isSameDay(lastPreviewDate, date);
    }

    if (shouldLoadMore && fromDate && toDate) {
      await store.dispatch('trainingPlan/GET_PREVIEW', {
        fromDate: format(fromDate, DateFormat.Date),
        toDate: format(toDate, DateFormat.Date),
      });
    }
  };

  const updateCalendar = () => {
    const endOfNextWeek = addWeeks(endOfISOWeek(currentDate.value), 1);
    const focus: TrainingPlanFocus[] = lastFocusIndex.value >= 0 && store.state.trainingPlan.focus.length
      ? store.state.trainingPlan.focus.slice(0, lastFocusIndex.value + 1)
      : store.state.trainingPlan.focus;

    const focuses = focus.map((focus) => {
      let weekIndex = 0;
      let weekHasActivities = false;
      let weeks: CalendarWeek[] = [];

      for (let i = 0; i <= focus.calendarDays; i++) {
        const date = addDays(focus.startDate, i);
        const dateString = format(date, DateFormat.Date);

        let activities: CalendarActivity[] = [];
        let status: CalendarDayStatus;

        if (isSameDay(date, currentDateUTC.value)) {
          status = CalendarDayStatus.Today;
        } else if (isAfter(date, endOfNextWeek)) {
          status = CalendarDayStatus.Unavailable;
        } else if (isAfter(date, currentDateUTC.value)) {
          status = CalendarDayStatus.Future;
        } else {
          status = CalendarDayStatus.Past;
        }

        if (store.state.trainingPlan.trainingPlan[dateString]) {
          weekHasActivities = true;
          activities = mapTrainingToCalendarActivities(
            store.state.trainingPlan.trainingPlan[dateString],
            isMetricSystem.value,
            timeZone.value,
          );
        } else if (store.state.trainingPlan.preview[dateString]) {
          weekHasActivities = true;
          activities = mapPreviewToCalendarActivities(
            store.state.trainingPlan.preview[dateString],
            isMetricSystem.value,
          );
        }

        if (status !== CalendarDayStatus.Unavailable && store.state.trainingPlan.importedActivities[dateString]) {
          weekHasActivities = true;
          activities = activities.concat(mapImportedToCalendarActivities(
            store.state.trainingPlan.importedActivities[dateString],
            isMetricSystem.value,
          ));
        }

        if (weeks[weekIndex] === undefined) {
          weeks[weekIndex] = {
            isCurrent: isSameDay(startOfISOWeek(date), startOfISOWeek(currentDate.value)),
            days: [],
          };
        }

        weeks[weekIndex].days.push({
          activities,
          date,
          dateKey: format(date, DateFormat.Date),
          dateLabel: format(date, 'E, MMM d'),
          status,
        });

        // If this week has no activities, exit early
        if (i > 0 && Number.isInteger((i + 1) / 7) && !weekHasActivities) {
          weeks = weeks.filter((week) => {
            return week.days.filter(day => day.activities?.length).length >= 1;
          });
          break;
        }

        // Prepare for a new week
        if (i > 0 && Number.isInteger((i + 1) / 7)) {
          weekIndex += 1;
          weekHasActivities = false;
        }
      }

      return {
        focus,
        weeks,
      };
    });

    store.dispatch('trainingPlan/UPDATE_CALENDAR', focuses);
  };

  const updatePrevCalendar = (numberOfDays: number, fromDate: Date) => {
    let weekIndex = 0;
    const weeks: CalendarWeek[] = [];

    for (let i = 0; i < numberOfDays; i++) {
      const date = addDays(fromDate, i);
      const dateString = format(date, DateFormat.Date);
      let activities: CalendarActivity[] = [];

      if (store.state.trainingPlan.trainingPlan[dateString]) {
        activities = mapTrainingToCalendarActivities(
          store.state.trainingPlan.trainingPlan[dateString],
          isMetricSystem.value,
          timeZone.value,
        );
      }

      if (store.state.trainingPlan.importedActivities[dateString]) {
        activities = activities.concat(mapImportedToCalendarActivities(
          store.state.trainingPlan.importedActivities[dateString],
          isMetricSystem.value,
        ));
      }

      if (weeks[weekIndex] === undefined) {
        weeks[weekIndex] = {
          isCurrent: false,
          days: [],
        };
      }

      weeks[weekIndex].days.push({
        activities,
        date,
        dateKey: format(date, DateFormat.Date),
        dateLabel: format(date, 'E, MMM d'),
        status: isBefore(date, createdDateUTC.value) ? null : CalendarDayStatus.Past,
      });

      // Prepare for a new week
      if (i > 0 && Number.isInteger((i + 1) / 7)) {
        weekIndex += 1;
      }
    }

    const weeksWithData = weeks.filter((week) => {
      return !!week.days.filter(day => !!day.activities.length).length;
    });

    if (weeksWithData.length !== weeks.length) {
      store.dispatch('trainingPlan/UPDATE_HAS_LOADED_EVERYTHING_IN_THE_PAST', true);
    }

    store.dispatch('trainingPlan/UPDATE_PREV_CALENDAR', weeksWithData);
  };

  const moveWorkout = async(id: string, fromDate: string, toDate: string): Promise<boolean | null> => {
    if (fromDate === toDate) {
      return null;
    }

    const result = await store.dispatch('trainingPlan/UPDATE_WORKOUT_DATE', {
      id,
      fromDate,
      toDate,
    });

    updateCalendar();

    // Update dashboard if necessary
    const currentDateString = format(currentDate.value, DateFormat.Date);
    if (fromDate === currentDateString || toDate === currentDateString) {
      store.dispatch('dashboard/CLEAR_DATA');
    }

    return result;
  };

  return {
    calendar,
    currentFocus,
    focusList,
    getInitialCalendar,
    getInitialFocus,
    getNextCalendar,
    getPrevCalendar,
    hasLoadedEverythingInTheFuture,
    hasLoadedEverythingInThePast,
    isLoadingFocus,
    isLoadingPreview,
    isLoadingTrainingPlan,
    moveWorkout,
    prevCalendar,
  };
};

const mapTrainingToCalendarActivities = (items: TrainingPlanItem[], useMetricSystem: boolean, timezone: string): CalendarActivity[] => {
  return items.map((item) => {
    let details: string[] | null = null;

    if (item.workout.categoryKey === 'event') {
      const eventTitle = item.event_title || item.workout.plan_event_title;
      details = eventTitle ? [eventTitle] : null;
    } else if (item.imported_activity) {
      details = getImportedActivityDetails(item.imported_activity, useMetricSystem);
    } else {
      const workoutDetails = getWorkoutDetails(
        item.workout.duration_min,
        item.workout.duration_max,
        item.workout.distance_min,
        item.workout.distance_max,
        item.workout.categoryKey,
        useMetricSystem,
      );
      details = workoutDetails ? [workoutDetails] : null;
    }

    return {
      details,
      id: item.id,
      title: item.workout.categoryTitleShort || item.workout.categoryTitle,
      type: item.workout.categoryKey,
      workoutStatus: getWorkoutStatus(item, timezone),
    };
  });
};

const mapPreviewToCalendarActivities = (items: TrainingPlanPreview[], useMetricSystem: boolean): CalendarActivity[] => {
  return items.map((item) => {
    const isEvent = item.workout_category_key === 'event';
    const details = isEvent
      ? item.event_title || item.workout_event_title
      : getWorkoutDetails(
        item.duration_min,
        item.duration_max,
        item.distance_min,
        item.distance_max,
        item.workout_category_key,
        useMetricSystem,
      );
    return {
      details: details ? [details] : null,
      id: null,
      title: item.workout_category_title_short || item.workout_category_title,
      type: item.workout_category_key,
      workoutStatus: WorkoutStatus.Unavailable,
    };
  });
};

const mapImportedToCalendarActivities = (items: ImportedActivity[], useMetricSystem: boolean): CalendarActivity[] => {
  return items.map((item) => {
    const details = getImportedActivityDetails(item, useMetricSystem);
    return {
      details: details.length ? details : null,
      id: item.id,
      title: item.name,
      type: null,
      workoutStatus: WorkoutStatus.Imported,
    };
  });
};
