export enum ConnectionType {
  TrainingPeaks = 1,
  Strava = 2,
  Garmin = 3,
  Wahoo = 4,
  Zwift = 5,
};

export type Connection = {
  id: number,
  type: ConnectionType,
  isWorkoutExportedEnabled: boolean,
};
