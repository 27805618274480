import { useContext, useStore } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';

import { State } from '~/data/types/store';

export const useEmailConfirmation = () => {
  const { $auth } = useContext();
  const store = useStore<State>();
  const hasConfirmedEmail = computed<boolean>(() => store.getters['user/hasConfirmedEmail']);

  const resend = async() => {
    await store.dispatch('user/resendConfirmationEmail');
  };

  const confirm = async(token: string): Promise<boolean | null> => {
    try {
      await store.dispatch('user/confirmEmail', token);
      await $auth.fetchUser();
      return true;
    } catch {
      return false;
    }
  };

  return {
    confirm,
    hasConfirmedEmail,
    resend,
  };
};
