import { format } from 'date-fns';
import { DateFormat } from '~/helpers/date';

const getDefaultState = () => ({
  hasRegenerationError: false,
  isLoadingMedia: true,
  isLoadingTrainingPlan: true,
  isLoadingCoachesMessage: true,
  media: [],
  trainingPlan: [],
  coachesMessage: null,
});

// initial state
export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_COACHES_MESSAGE({ commit }) {
    try {
      commit('SET_IS_LOADING_COACHES_MESSAGE', true);

      const response = await this.$axios.$get('/api/coaches-message/today');

      commit('SET_COACHES_MESSAGE', response.data?.message || null);
    } catch {
      // Do nothing
    } finally {
      commit('SET_IS_LOADING_COACHES_MESSAGE', false);
    }
  },
  async GET_MEDIA({ commit }) {
    try {
      commit('SET_IS_LOADING_MEDIA', true);

      const response = await this.$axios.$get('/api/workout-feed-announcements');

      commit('SET_MEDIA', response.data.filter(item => item.media));
    } catch {
      // Do nothing
    } finally {
      commit('SET_IS_LOADING_MEDIA', false);
    }
  },
  async GET_TRAINING_PLAN({ commit }) {
    try {
      commit('SET_IS_LOADING_TRAINING_PLAN', true);

      const response = await this.$axios.$get('/api/training-plans', {
        params: {
          type: 'today',
        },
        hideToastr: true,
      });

      commit('SET_TRAINING_PLAN', response.data);
    } catch (err) {
      if (err.response.data.message.includes('regenerating')) {
        commit('SET_HAS_REGENERATION_ERROR', true);
        return Promise.reject(err);
      }
    } finally {
      commit('SET_IS_LOADING_TRAINING_PLAN', false);
    }
  },
  async MARK_MEDIA_AS_WATCHED({ commit }, id) {
    try {
      await this.$axios.$put(`/api/workout-feed-announcements/mark-as-watched/${id}`);
      commit('SET_MEDIA_AS_WATCHED', id);
    } catch {
      // Do nothing
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_IS_LOADING_COACHES_MESSAGE(state, value) {
    state.isLoadingCoachesMessage = value;
  },
  SET_IS_LOADING_MEDIA(state, value) {
    state.isLoadingMedia = value;
  },
  SET_IS_LOADING_TRAINING_PLAN(state, value) {
    state.isLoadingTrainingPlan = value;
  },
  SET_COACHES_MESSAGE(state, value) {
    state.coachesMessage = value;
  },
  SET_TRAINING_PLAN(state, action) {
    state.trainingPlan = action[Object.keys(action)[0]] || [];
  },
  SET_MEDIA(state, data) {
    state.media = data;
  },
  SET_HAS_REGENERATION_ERROR(state, status) {
    state.hasRegenerationError = status;
  },
  SET_MEDIA_AS_WATCHED(state, id) {
    state.media = state.media.map((item) => {
      return {
        ...item,
        watched_at: item.id === id
          ? format(new Date(), DateFormat.ISO)
          : item.watched_at,
      };
    });
  },
  SET_TRAINING_PLAN_ITEM(state, trainingPlan) {
    state.trainingPlan = state.trainingPlan.map(item => item.id === trainingPlan.id ? trainingPlan : item);
  },
};

export const getters = {};
